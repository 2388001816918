import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { SetTitle, SetTags } from '../../store';
import { StringUtility } from '@teamfoster/sdk/text-utility';

@Injectable({ providedIn: 'root' })
export class ContentPageMetaGuard {
  constructor(private store: Store<fromStore.ContentState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getSelectedContentPage).pipe(
      tap(page => {
        this.store.dispatch(SetTitle({ title: `${page.title} - KCAP` }));
        this.store.dispatch(
          SetTags({
            properties: {
              'description':
                StringUtility.Truncate(StringUtility.StripHTML(page.intro), 157, '...') ||
                StringUtility.Truncate(StringUtility.StripHTML(page.text), 157, '...'),
              'og:image': page.imageUrl,
              'og:title': `${page.title} - KCAP`,
              'og:description':
                StringUtility.Truncate(StringUtility.StripHTML(page.intro), 157, '...') ||
                StringUtility.Truncate(StringUtility.StripHTML(page.text), 157, '...'),
            },
          })
        );
      }),
      map(a => true),
      take(1)
    );
  }
}
